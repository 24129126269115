import React, { useEffect, useState } from 'react';
import { FaKey } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../Assets/Logo.jpg';
import img from '../Assets/Man-1.jpg';
import accountClass from '../Auth/account.class';

function ChangePassword() {
  const [accountDetails,setAccountDetails]= useState()
  useEffect(()=>{
      const getAccountDetails = async()=>{
          try{
              const res = await accountClass.getAccount()
              console.log("res from bank",res?.data[0])
              setAccountDetails(res?.data[0])
          }catch(e){

          }
      }
      getAccountDetails();
  },[])
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center"
      style={{
        background: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
      }}
    >
      <div className="bg-white shadow-xl p-6 mt-10 rounded-md w-full max-w-md">
        <div className="flex flex-col">
          {/* Logo */}
          <img
            src={logo}
            alt="logo"
            className="w-[100px] mx-auto rounded-full pb-10"
          />
          <h1 className="font-medium text-2xl text-center tracking-wide text-blue-900">
            Welcome
          </h1>
          <div className="border-b mt-5"></div>

          {/* Profile Image */}
         
          <h1 className="font-medium text-2xl text-center tracking-wide mt-5 text-blue-900">
{accountDetails?.accountName}
          </h1>
          <h1 className="font-medium text-xl text-gray-700 text-center tracking-wide mt-5">
            Account Holder
          </h1>
          <div className="border-b mt-5"></div>

          {/* Old Password */}
          <h1 className="font-medium text-xl text-center tracking-wide mt-5 text-blue-900">
            Enter Old Password
          </h1>
          <FaKey className="mx-auto mb-3 text-blue-900" />
          <p className="text-red-500 text-center"></p>
          <input
            className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
            placeholder="Insert Old Password"
            id="old-password"
            type="password"
          />

          {/* New Password */}
          <h1 className="font-medium text-xl text-center tracking-wide mt-5 text-blue-900">
            Enter New Password
          </h1>
          <FaKey className="mx-auto mb-3 text-blue-900" />
          <p className="text-red-500 text-center"></p>
          <input
            className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
            placeholder="Insert New Password"
            id="new-password"
            type="password"
          />
          <div className="border-b mt-5"></div>

          {/* Buttons */}
          <div className="flex flex-row space-x-8 mx-auto mt-5">
            <Link>
              <button className="bg-blue-600 hover:bg-blue-800 text-white px-4 py-2 rounded cursor-pointer">
                Proceed
              </button>
            </Link>
            <Link to="/bankprofile">
              <button className="text-blue-600 hover:underline px-4 py-2 rounded cursor-pointer">
                Go Back
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
