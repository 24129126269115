import React from 'react'
import shop from '../Assets/shopping-1.png'
import { FaCircleCheck } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

function OnlineShopping() {
  return (
    <div className='w-full px-4 mt-10 md:mt-20'>
      <div className='max-w-[1240px] gap-10 md:gap-24 mx-auto grid md:grid-cols-2'>

        <div className="flex flex-col justify-center">
          <p className="text-teal-400 text-xl ">
          Online Shopping
          </p>
          <h1 className="mt-5 text-3xl md:text-5xl font-medium">
          Shop Online Make In Online Banking Payments
          </h1>
          <p className="mt-10 text-gray-500 tracking-wide">
          We look forward to introducing you to our outstanding products and services while providing the local customer service you already know.
          </p>

          <div className='flex py-2 mt-5'>
            <div className='flex flex-row gap-3'>
              <FaCircleCheck className='text-teal-400' size={25} />
              <p className='font-medium'>Cards that work all across the world.</p>
            </div>
          </div>

          <div className='flex py-2'>
            <div className='flex flex-row gap-3'>
              <FaCircleCheck className='text-teal-400' size={25} />
              <p className='font-medium'>Highest Returns on your investments.</p>
            </div>
          </div>

          <div className='flex py-2'>
            <div className='flex flex-row gap-3'>
              <FaCircleCheck className='text-teal-400' size={25} />
              <p className='font-medium'>No ATM fees. No minimum balance. No overdrafts.</p>
            </div>
          </div>

          <Link to='/register'><button className='bg-teal-400 text-white text-center p-4 w-36 rounded-md mx-0 mt-10'>Signup Today</button></Link>
        </div>

        <div className="flex justify-center items-center">
          <img src={shop} alt="/img" className=" mx-auto" />
        </div>
      </div>
    </div>
  )
}

export default OnlineShopping