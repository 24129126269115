import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../Components/Navbar'
import { FaArrowLeft, FaEnvelope, FaKey, FaPhone, FaUser, FaUserCheck } from 'react-icons/fa6'


function Register() {


    return (
        <div className="antialiased">
            <Navbar />

            <div className="flex relative">
                <Link to="/">
                    <FaArrowLeft className=" absolute mx-2 mt-1" />
                    <h1 className="underline 2xl  mx-8 font-bold">Go Back</h1>
                </Link>
            </div>
            <h1 className="text-4xl text-blue-950 mx-auto flex justify-center mb-10 font-bold">
                <Link to="/">Horizon View Bank</Link>
            </h1>
            <div className="flex w-full min-h-screen justify-center items-center pb-[4rem]">
                <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-gray-800 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
                    <div className="relative flex justify-center items-center w-full">
                        <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
                        <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
                        <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
                            <h1 className="text-gray-800 font-bold text-2xl mx-auto flex justify-center pb-8">
                            Create Account
                            </h1>
                            <form
                                
                                className="flex flex-col justify-center"
                            >
                                <h1
                                    className="mx-auto font-bold pt-4 gap-4 pb-6"
                                    htmlFor="username"
                                >
                                    UserName
                                </h1>
                                <FaUser className="mx-auto mb-3" />
                                <p className="text-red-500 text-center"></p>
                                <input
                                    id="username"
                                    
                                    // icon={<LockClosedIcon />}
                                    className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
                                    placeholder= 'Enter UserName'
                                    type="text"
                                    required
                                />
                                <h1 className="mx-auto font-bold pt-4 pb-6">FullName</h1>
                                <FaUserCheck className="mx-auto mb-3" />
                                <p className="text-red-500 text-center"></p>
                                <input
                                    className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
                                    id="fullName"
                                    
                                    placeholder='Enter FullName'
                                />

                                <h1 className="mx-auto font-bold pt-4 pb-6">Email</h1>
                                <FaEnvelope className="mx-auto mb-3" />
                                <p className="text-red-500 text-center"></p>
                                <input
                                    className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
                                    id="email"
                                  
                                    placeholder='name@gmail.com'
                                />

                                <h1 className="mx-auto font-bold pt-4 pb-6">Phone Number</h1>
                                <FaPhone className="mx-auto mb-3" />
                                <p className="text-red-500 text-center">
                                    
                                </p>
                                <input
                                    className="mx-auto text-black p-2  border border-gray-400 rounded-md w-[200px]"
                                    id="phoneNumber"
                                    
                                    placeholder='Enter PhoneNumber'
                                />

                                <h1 htmlFor="password" className="mx-auto font-bold pt-4 pb-6">
                                Password
                                </h1>

                                <FaKey className="mx-auto mb-3" />
                                <p className="text-red-500 text-center"></p>
                                <input
                                    className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
                                    placeholder='Choose Password'
                                    id="password"
                                    
                                    type="password"
                                    required
                                />
                                <h1 htmlFor="confirm_pwd" className="mx-auto font-bold pt-4 pb-6">
                                Confirm Password
                                </h1>

                                <FaKey className="mx-auto mb-3" />
                                <p className="text-red-500 text-center">
                                    
                                </p>
                                <input
                                    className="mx-auto p-2  border border-gray-400 rounded-md w-[200px]"
                                    placeholder='Confirm Password'
                                    id="confirmPassword"
                                    
                                   
                                    type="password"
                                    required
                                />

                                <button
                                    type="submit"
                                    className="bg-gray-800 mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:bg-blue-400 hover:text-gray-800"
                                >
                                    {/* {isLoading ? "loading" : "Create User"} */}
                                    Create User
                                </button>
                            </form>
                            <p className="text-black mx-auto pb-6">
                            Already have an account ?
                                <span className="font-bold">
                                    <Link to="/login">Login</Link>
                                </span>
                            </p>
                            <p className="text-black mx-auto">
                            ©Copyright 2024 Horizon View Bank All Rights Reserved
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register