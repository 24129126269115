import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaLock, FaFileAlt, FaSignOutAlt, FaExchangeAlt, FaQuestionCircle } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { FaClipboardUser } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import img from '../Assets/Man-1.jpg'
import accountClass from "../Auth/account.class";

const BankingProfile = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   
    const [accountDetails,setAccountDetails]= useState()
    useEffect(()=>{
        const getAccountDetails = async()=>{
            try{
                const res = await accountClass.getAccount()
                console.log("res from bank",res?.data[0])
                setAccountDetails(res?.data[0])
            }catch(e){

            }
        }
        getAccountDetails();
    },[])
    return (
        <div className="min-h-screen flex">
            {/* Sidebar */}
            <div
                className={`w-64 bg-white shadow-lg p-4 fixed lg:static lg:h-full h-full lg:min-h-screen transition-transform duration-300 ease-in-out z-50 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } lg:translate-x-0`}
                style={{ height: "100vh" }}
            >
                <div className="flex justify-between items-center border-b pb-4">
                    <h1 className="text-lg font-bold text-blue-950 tracking-wide">Horizon View Bank</h1>
                    <button className="lg:hidden text-xl" onClick={() => setIsSidebarOpen(false)}>
                        <AiOutlineClose />
                    </button>
                </div>
                <p className="mt-2 text-gray-800 text-xl tracking-wide">{accountDetails?.accountName}</p>
                <p className="text-gray-800 text-xl tracking-wide mt-3">{accountDetails?.AccountNumber}</p>
                <p className="text-green-600 mt-4 font-semibold text-xl">Status: Active</p>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Personal Menu</h3>
                    <ul className="mt-2">
                        <Link to='/bankprofile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaClipboardUser className="mr-2 text-blue-700" size={20} /> Dashboard
                        </li></Link>
                        <Link to='/profile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaUser className="mr-2 text-blue-700" size={20} /> My Profile
                        </li></Link>
                        <Link to='/changepassword'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaLock className="mr-2 text-blue-700" size={20} /> Change Password
                        </li></Link>
                        <Link to='/statement'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaFileAlt className="mr-2 text-blue-700" size={20} /> My Statement
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Transfers</h3>
                    <ul className="mt-2">
                    <Link to='/transfer'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaExchangeAlt className="mr-2 text-blue-700" size={20} /> Inter-Bank Transfer
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Settings</h3>
                    <ul className="mt-2">
                        <li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaQuestionCircle className="mr-2 text-blue-700" size={20} /> Get Support
                        </li>
                       <Link to='/login'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaSignOutAlt className="mr-2 text-blue-700" size={20} /> Logout
                        </li></Link>
                    </ul>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1">
                {/* Navbar */}
                <nav className="bg-blue-600 text-white p-4 flex justify-between items-center">
                    <button className="lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                        ☰
                    </button>
                    <span className="font-bold hidden lg:block"> VExpress Platinum</span>
                    <div className="flex items-center space-x-4">
                        <input className="p-2 rounded bg-white text-black" type="text" placeholder="Search" />
                        <FaBell className="cursor-pointer" />
                        <FaCog className="cursor-pointer" />
                        <div className="relative group">
                            {/* <img
                                className="w-8 h-8 rounded-full cursor-pointer"
                                src={img}
                                alt="Profile"
                            /> */}
                            <div
                                className="absolute right-0 mt-2 bg-blue-600 text-white rounded shadow-lg p-2 w-48 z-50 hidden group-hover:block"
                            >
                                <p className="font-semibold text-center">{accountDetails?.accountName}</p>
                                <ul className="mt-2">
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaUser className="mr-2" /> Profile Details
                                    </li>
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaBell className="mr-2" /> Notifications
                                    </li>
                                    <Link to='/login'><li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaSignOutAlt className="mr-2" /> Logout
                                    </li></Link>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>

                {/* Content */}
                <div className="p-6 space-y-6 bg-gray-100">
                    <h1 className="text-2xl font-light text-gray-600 tracking-wider">Hi {accountDetails?.accountName}, welcome back!</h1>
                    <div className="border-b"></div>
                    <div className="grid lg:grid-cols-2 gap-4">
                        <div className="p-4 bg-white shadow rounded">
                            <h3 className="tracking-wider text-gray-400">Total Balance</h3>
{/*                             <p className="text-2xl md:text-3xl tracking-wider text-blue-900 font-bold">${accountDetails?.amount || 0.00}</p> */}
                            <p className="text-2xl md:text-3xl tracking-wider text-blue-900 font-bold">
  ${accountDetails?.amount ? parseFloat(accountDetails.amount.replace(/,/g, '')).toFixed(2) : '27,350,840.00'}
</p>
                        </div>
                        <div className="p-4 bg-white shadow rounded">
                            <h3 className="tracking-wider text-gray-400">Available Balance</h3>
{/*                             <p className="text-2xl md:text-3xl tracking-wider text-blue-900 font-bold">${accountDetails?.available_amount || 0.00}</p> */}
                            <p className="text-2xl md:text-3xl tracking-wider text-blue-900 font-bold">
  ${accountDetails?.amount ? parseFloat(accountDetails.amount.replace(/,/g, '')).toFixed(2) : '27,350,840.00'}
</p>
                        </div>
                        <div className="p-4 bg-yellow-400 shadow rounded col-span-full flex flex-col">
                            <h3 className="text-center text-xl pb-5">Refer Friends and Earn Rewards!</h3>
                            <div className="border-b border-gray-800"></div>
                            <p className="text-center p-3 text-gray-700">You can earn: 1,000 USD Membership Rewards points for each approved referral – up to 55,000 USD Membership Rewards points per calendar year.</p>
                            <button className="bg-white text-orange-700 hover:bg-black hover:text-white px-4 w-36 mx-auto py-2 rounded">Send Referral</button>
                        </div>
                    </div>
                    <Link to='/transfer'><button className="bg-blue-600 hover:bg-blue-800 text-white px-4 py-2 rounded">Make Payment</button></Link>
                    <div className="grid lg:grid-cols-2 gap-4 mt-6">
                        <div className="p-4 bg-white shadow rounded">
                            <h3 className="text-gray-600 text-xl tracking-wide pb-5">Tips: Change your Internet Banking Password Frequently to keep your Account Safe</h3>
                            <div className="border-b"></div>
                            <p className="mt-5 tracking-wide font-light text-xl">Account Number: <span className="font-bold tracking-wider text-gray-600">{accountDetails?.AccountNumber}</span></p>
                            <p className="mt-3 tracking-wide font-light text-xl">Account Holder: <span className="font-bold text-gray-600">{accountDetails?.accountName}</span></p>
                            <p className="mt-3 tracking-wide font-light text-xl">Account Type: <span className="font-bold text-gray-600">{accountDetails?.AccountType}</span></p>
                            <p className="mt-3 font-light text-xl">Account Status: <span className="text-green-600 font-bold">Active</span></p>
                        </div>
                        <div className="p-4 bg-white shadow rounded">
                            <h3 className="text-xl tracking-wide text-gray-600 pb-5">Quick Links</h3>
                            <div className="border-b"></div>
                            <ul>
                                <Link to='/transfer'><li className="mt-5 font-medium text-gray-600 hover:text-green-400 hover:underline">Local Transactions</li></Link>
                                <li className="mt-5 font-medium text-gray-600 hover:text-green-400 hover:underline cursor-pointer">International Transfer</li>
                                <li className="mt-5 font-medium text-gray-600 hover:text-green-400 hover:underline cursor-pointer">Account Settings</li>
                            </ul>
                        </div>
                    </div>
                    <div className="p-4 bg-white shadow rounded">
                        <h3>Transaction History</h3>
                        <div className="border-b mt-5"></div>
                        <table className="w-full text-left mt-5">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="5" className="text-center text-gray-500">
                                        No transactions available
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button className="bg-blue-600 hover:bg-blue-800 text-white px-4 py-2 rounded mt-4">Print Receipt</button>
                    </div>
                </div>

                {/* Footer */}
                <footer className="text-center text-gray-500 py-4">
                    &copy; 2024 VExpress Bank. All rights reserved.
                </footer>
            </div>
        </div>
    );
};

export default BankingProfile;
