import React from 'react';
import backgroundImage from '../Assets/hero.png'; // Ensure this path is correct
import herop from '../Assets/heroPE.png'; // Ensure this path is correct
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <div
      className="w-full h-[100vh] bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="max-w-[1240px] gap-10 mx-auto grid md:grid-cols-2 mt-14 px-4">
        {/* Text Content */}
        <div className="flex flex-col justify-center">
          <p className="text-teal-400 text-xl ">
            Simple. Transparent. Secure
          </p>
          <h1 className="mt-5 text-4xl md:text-6xl font-medium">
            Fast & Secure Online Money Transfer
          </h1>
          <p className="mt-10 text-gray-500 tracking-wide">
            Help us build tomorrow's bank. As a global organization, we offer opportunities around the world and careers that are both challenging and rewarding.
          </p>
         <Link to='/login'> <button className="mt-5 bg-teal-400 text-white hover:bg-blue-900 py-2 w-24 rounded ml-0 font-medium tracking-wide">
            SIGN IN
          </button></Link>

        </div>

        {/* Image */}
        <div className="flex justify-center items-center">
          <img src={herop} alt="/img" className=" mx-auto" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
