import React, { useEffect, useState } from 'react'
import logo from '../Assets/statementimg.jpg'
import { Link } from 'react-router-dom'
import accountClass from '../Auth/account.class'

function Statement() {
  const [accountDetails,setAccountDetails]= useState()
  useEffect(()=>{
      const getAccountDetails = async()=>{
          try{
              const res = await accountClass.getAccount()
              console.log("res from bank",res?.data[0])
              setAccountDetails(res?.data[0])
          }catch(e){

          }
      }
      getAccountDetails();
  },[])
  return (
    <div className='bg-gray-900 w-full min-h-screen flex items-center justify-center'>
        <div className="bg-white shadow-xl p-6 mt-10 rounded-md w-full max-w-md">
        <div className="flex flex-col">
            <div className='flex flex-row mx-auto space-x-5 mt-5'>
                <Link to='/login'><p className='font-light tracking-wider underline'>Sign In</p></Link>
                <Link to='/changepassword'><p className='font-light tracking-wider underline'>Forgot Password</p></Link>
            </div>
          {/* Logo */}
          <img
            src={logo}
            alt="logo"
            className=" mx-auto mt-10 pb-10"
          />
          <h1 className="font-medium text-2xl text-center tracking-wide text-blue-900">
            Hello, {accountDetails?.accountName}
          </h1>
          <p className='text-gray-700 text-center font-light'>Opps! It seems your statement is empty. Consider making a transaction or contact West Globe Bank Live Support.</p>
          <div className="border-b mt-5"></div>

          <h1 className="font-medium text-2xl text-center tracking-wide mt-5 text-blue-900">
            Need Help?
          </h1>
          <p className="font-light text-gray-700 text-center tracking-wide mt-5">
          If you have any questions you can simply reply to this email or find our contact information below. Also contact us at support@horizonviewbk.org
          </p>
          <div className="border-b mt-5"></div>

       
        </div>
      </div>
    </div>
  )
}

export default Statement
