import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import SmartBanking from "./SmartBanking";
import Features from "./Features";
import BankingSecurity from "./BankingSecurity";
import MobileApp from "./MobileApp";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

function Home() {
    return (
        <div>
            <Navbar />
            <Hero />
            <SmartBanking />
            <Features />
            <BankingSecurity />
            <MobileApp />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default Home