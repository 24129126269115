import React from 'react'
import img1 from '../Assets/card.png'
import img2 from '../Assets/salary.png'
import img3 from '../Assets/bank.png'

function Features() {
  return (
    <div className='w-full px-4 bg-gray-100 pb-10 md:pb-20'>
      <div className='mx-auto text-center flex flex-col '>
        <h1 className='text-center mx-auto text-teal-400 text-xl mt-10 font-medium'>
          What We Do
        </h1>
        <h1 className='text-3xl md:text-5xl mt-5'>Payment Services Worldwide</h1>
      </div>

      <div className='max-w-[1240px] gap-10 mx-auto grid md:grid-cols-3 '>
        <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
          <img src={img1} alt='/img' className='text-center mx-auto w-12' />
          <h1 className="font-medium text-center text-gray-800 text-3xl">
            Protect Your Card
          </h1>

          <div className="relative p-6">

            <div className="absolute bottom-0 left-0 w-full">
              <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
              <div className="h-0.5 bg-gray-800 w-[200px] mx-auto mb-2"></div> {/* Long line in the middle */}
              <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
            </div>
          </div>
          <p className="p-4 font-medium text-gray-800">
            With Horizon View Bank Services you can access all your banking accounts with the Bank, 24 hours a day, 365 days a year, from anywhere in the world.
          </p>
        </div>

        <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
          <img src={img2} alt='/img' className='text-center mx-auto w-12' />
          <h1 className="font-medium text-center text-gray-800 text-3xl">
            Send Money
          </h1>


          <div className="relative p-6">

            <div className="absolute bottom-0 left-0 w-full">
              <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
              <div className="h-0.5 bg-gray-800 w-[200px] mx-auto mb-2"></div> {/* Long line in the middle */}
              <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
            </div>
          </div>
          <p className="p-4 font-medium text-gray-800">
            With our new Elite & Preferred Checking account, Local and national discounts on our exclusive app and lots more. All the perks right at your finger tips.
          </p>
        </div>

        <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
          <img src={img3} alt='/img' className='text-center mx-auto w-12' />
          <h1 className="font-medium text-center text-gray-800 text-3xl">
            Online Banking
          </h1>


          <div className="relative p-6">

            <div className="absolute bottom-0 left-0 w-full">
              <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
              <div className="h-0.5 bg-gray-800 w-[200px] mx-auto mb-2"></div> {/* Long line in the middle */}
              <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
            </div>
          </div>
          <p className="p-4 font-medium text-gray-800">
            We know businesses have their unique challenges, so let Horizon View Bank help you find your business solutions.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Features