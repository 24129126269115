import api from './api'
class BookingService {
   
    async getAccount(data){
        try{
            const res = await api.get('/account',
                {
                    params:data
                }
            )
            return res
        }catch(err){
            return err
        }
    }
}

const accountClass = new BookingService();
export default accountClass;