import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Navbar from '../Components/Navbar';
import { FaArrowLeft, FaEnvelope, FaKey } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import AuthService from '../Auth/AuthService';
import { setAuthUser } from '../Auth/auth';
import { ToastContainer, toast } from 'react-toastify';

function Login() {
  const formik = useFormik({
    initialValues: {
      accountNumber: '',
      password: '',
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string()
        .required('Account Number is required')
        ,
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters'),
    }),
    onSubmit: async(values) => {
      try{
        const res = await AuthService.login({
          email:values.accountNumber,
          password: values.password
        })
        console.log("res",res?.response?.data?.message,res?.data?.success,res?.data?.token)
        if(res?.data?.token){
          toast.success("Login Successful")
          setAuthUser(res?.data?.token)
          window.location.href = "/bankprofile"
        }else{
          toast.error(res?.response?.data?.message || "An error occurred while logging in")
        }
      }catch(err){

      }
     
    },
  });

  return (
    <div className="antialiased">
      <Navbar />
      <div className="flex relative mt-10">
        <Link to="/">
          <FaArrowLeft className="absolute mx-2 mt-1" />
          <h1 className="underline 2xl mx-8 font-bold">Go Back</h1>
        </Link>
      </div>
      <h1 className="text-4xl text-blue-950 mx-auto flex justify-center mb-10 font-bold">
        <Link to="/">Horizon View Bank</Link>
      </h1>
      <div className="flex w-full min-h-screen justify-center items-center pb-[4rem]">
        <div className="flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-gray-800 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden">
          <div className="relative flex justify-center items-center w-full">
            <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -right-28 -top-28"></div>
            <div className="absolute z-0 w-40 h-40 bg-teal-400 rounded-full -left-28 -bottom-16"></div>
            <div className="relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
              <form onSubmit={formik.handleSubmit} className="flex flex-col justify-center">
                <h1 className="mx-auto font-bold pt-4 pb-6">Account Number</h1>
                <FaEnvelope className="mx-auto mb-3" />
                {formik.touched.accountNumber && formik.errors.accountNumber ? (
                  <p className="text-red-500 text-center">{formik.errors.accountNumber}</p>
                ) : null}
                <input
                  className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
                  placeholder="Enter Account Number"
                  id="accountNumber"
                  name="accountNumber"
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <h1 className="mx-auto font-bold pt-4 pb-6">Password</h1>
                <FaKey className="mx-auto mb-3" />
                {formik.touched.password && formik.errors.password ? (
                  <p className="text-red-500 text-center">{formik.errors.password}</p>
                ) : null}
                <input
                  className="mx-auto p-2 border border-gray-400 rounded-md w-[200px]"
                  placeholder="Insert Password"
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="bg-gray-800 mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:bg-blue-400 hover:text-gray-800"
                  >
                   {formik.isSubmitting ? "Loading..." :" Login" }
                  </button>
                </div>
                <p className="text-black mx-auto pb-6">
                  Don't have an account?{' '}
                  <span className="font-bold">
                    <Link to="/register">Register</Link>
                  </span>
                </p>
                <p className="text-black mx-auto">©Copyright 2024 Horizon View Bank All Rights Reserved</p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
