import React from 'react'
import Navbar from '../Components/Navbar'
import { FaEnvelope, FaFacebook, FaInstagram, FaLocationArrow } from 'react-icons/fa6'
import { FaPhoneAlt } from 'react-icons/fa'

function ContactPage() {
  return (
    <div>
        <Navbar />
        <h1 className='text-blue-950 text-4xl md:text-6xl mx-auto text-center mt-10 '>CONTACT US</h1>
            <div className="relative p-6"> 
             <div className="absolute bottom-0 left-0 w-full">
               <div className="h-0.5 bg-gray-800 w-12 mx-auto mb-1"></div> {/* Short line on top */}
                 <div className="h-0.5 bg-gray-800 w-[200px] md:w-[400px] mx-auto mb-2"></div> {/* Long line in the middle */}
                     <div className="h-0.5 bg-gray-800 w-12 mx-auto"></div> {/* Short line on bottom */}
                     </div>
                 </div>



                 <div className='antialiased'>
      <div className='flex w-full min-h-screen justify-center items-center pb-[4rem] mt-[100px] sm:mt-[100px] md:mt-[100px]'>
        <div className='flex flex-col md:flex-row md:space-x-6 md:space-y-0 space-y-6 bg-blue-950 w-full max-w-4xl p-8 sm:p-12 rounded-xl shadow-lg text-white overflow-hidden'>

          <div className='flex flex-col space-y-8 justify-between'>
            <div>
              <h1 className='font-bold text-4xl tracking-wide'>Contact Us</h1>
              <p className='pt-2 text-white text-sm'>Our support team is quick to respond and eager to assist you with any questions or enquiries.</p>
            </div>
            <div className='inline-flex space-x-2 items-center'>
              <FaPhoneAlt className='text-cream text-xl'/>
              <span>+(1)123-456-7890</span>
            </div>
            <div className='inline-flex space-x-2 items-center'>
              <FaLocationArrow className='text-cream text-xl'/>
              <span>St. Here Mandalay, New York, U.S.A</span>
            </div>
            <div className='inline-flex space-x-2 items-center'>
              <FaEnvelope className='text-cream text-xl'/>
              <span>contact@horizonviewbank.org</span>
            </div>
           

            <div className='flex space-x-4 text-lg'>
            <a href='/'> <FaFacebook className='cursor-pointer hover:text-teal-400' size={30}/></a>                                             
              <a href='/'><FaInstagram className='cursor-pointer hover:text-teal-400' size={30}/></a>
            </div>
          </div>

          <div className='relative'>
          <div className='absolute z-0 w-40 h-40 bg-cream rounded-full -right-28 -top-28'></div    >
          <div className='absolute z-0 w-40 h-40 bg-cream rounded-full -left-28 -bottom-16'></div>
            <div className= 'relative z-0 bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80'>


              <form className='flex flex-col space-y-4'>
                <div>
                  <label className='text-sm '>Your Name</label>
                </div>

                <div>
                  <input type='text' placeholder='Your Name' name='user_name' required className='ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 '
                  />
                </div>

                <div>
                  <label className='text-sm '>Email Address</label>
                </div>

                <div>
                  <input type='email' placeholder='Email Address' name='user_email' required className='ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 '
                  />
                </div>

                {/* <div>
                  <label className='text-sm '>Required Service</label>
                </div>

                <div>
                  <input type='text' placeholder='Required Service' name='submit' required className='ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 '
                  />
                </div> */}

                <div>
                  <label className='text-sm '>Message</label>
              
                  <textarea name='message' placeholder='Message'
                  rows='4'
                   className='ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 '
                  ></textarea>
                </div>

                  <button type='submit' className='inline-block self-end bg-cream text-gray-800 border hover:bg-teal-400 hover:text-white border-gray-500 font-bold rounded-lg px-6 py-2 uppercase text-sm'>Send Message</button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ContactPage