import React, { useState, useEffect } from "react";

const DateTimeDisplay = () => {
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    // Get the current date and time
    const now = new Date();
    const formattedDateTime = now.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    // Set the formatted date and time to state
    setDateTime(formattedDateTime);
  }, []); // The empty dependency array ensures it runs only once when the component mounts.

  return <p className="font-medium text-gray-700">{dateTime}</p>;
};

export default DateTimeDisplay;
