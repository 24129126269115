import React from 'react'
import { FaCircleCheck } from 'react-icons/fa6'
import goal from '../Assets/goal-1.jpg'

function Goals() {
    return (
        <div className='w-full px-4 mt-10 md:mt-20 pb-10 md:pb-20'>
            <div className='max-w-[1240px] gap-10 md:gap-24 mx-auto grid md:grid-cols-2'>

                <div className="flex justify-center items-center">
                    <img src={goal} alt="/img" className=" mx-auto" />
                </div>


                <div className="flex flex-col justify-center">
                    <p className="text-teal-400 text-xl ">
                    Goal Setting
                    </p>
                    <h1 className="mt-5 text-3xl md:text-5xl font-medium">
                    Manage Your Money With Online Banking Solution
                    </h1>
                    <p className="mt-10 text-gray-500 tracking-wide">
                    We know businesses have their unique challenges, so let Horizon View Bank help you find your business solutions. Introducing our Equity Bank Reward Credit Cards. Explore which card is right for your business.
                    </p>

                    <div className='flex py-2 mt-5'>
                        <div className='flex flex-row gap-3'>
                            <FaCircleCheck className='text-teal-400' size={25} />
                            <p className='font-medium'>Learn To Budget</p>
                        </div>
                    </div>

                    <div className='flex py-2'>
                        <div className='flex flex-row gap-3'>
                            <FaCircleCheck className='text-teal-400' size={25} />
                            <p className='font-medium'>Create An Emergency Fund</p>
                        </div>
                    </div>

                    <div className='flex py-2'>
                        <div className='flex flex-row gap-3'>
                            <FaCircleCheck className='text-teal-400' size={25} />
                            <p className='font-medium'>Get Out Of Debt</p>
                        </div>
                    </div>

                    <div className='flex py-2'>
                        <div className='flex flex-row gap-3'>
                            <FaCircleCheck className='text-teal-400' size={25} />
                            <p className='font-medium'>Save And Invest Your Money</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Goals