import React from 'react';
import Navbar from '../Components/Navbar';
import abt from '../Assets/about.png';
import SmartBanking from '../Components/SmartBanking';
import Features from '../Components/Features';
import OnlineShopping from '../Components/OnlineShopping';
import InApp from '../Components/InApp';
import Goals from '../Components/Goals';
import Testimonials from '../Components/Testimonials';
import BankingSecurity from '../Components/BankingSecurity';
import Footer from '../Components/Footer';

function AboutPage() {
    return (
        <div>
            <Navbar />

            <div className="w-full bg-teal-50 px-4 py-14 sm:py-12"> {/* Adjusted padding for smaller screens */}
                <div className="max-w-[1240px] grid md:grid-cols-2 mx-auto">
                    <div className="flex flex-col justify-center mt-[-20px]">
                        <h1 className="mt-5 text-2xl md:text-5xl font-medium">
                            About Us
                        </h1>
                        <p className="text-gray-500 font-medium mt-5">
                            Home <span className="mx-3"> &gt; </span> <span className="text-teal-400">About Us</span>
                        </p>
                    </div>

                    {/* Image section - Hidden on small screens */}
                    <div className="flex justify-center items-center hidden md:block">
                        <img src={abt} alt="About Us" className="mx-auto" />
                    </div>
                </div>
            </div>

            <SmartBanking />
            <div className='mt-5 md:mt-10'>
            <Features />
            </div>
            <OnlineShopping />
            <InApp />
            <Goals />
            <Testimonials />
            <div className='pb-10 md:pb-20'>
            <BankingSecurity />
            </div>
            <Footer />
        </div>
    );
}

export default AboutPage;
