import React from 'react'
import { FaApple, FaGooglePlay } from 'react-icons/fa'

function InApp() {
    return (
        <div className='w-full px-4 pb-10 md:pb-10 bg-blue-950 mt-5'>
            <div className='max-w-[1240px] grid md:grid-cols-2 mx-auto'>
                <div>
            <h1 className="mt-5 text-center text-white text-3xl md:text-4xl font-medium md:w-[100%]">
    You Can Find All Things You Need In Our App
</h1>
</div>

<div className='flex flex-row space-x-4 mt-5'>
                <button className="bg-blue-900 inline-block mt-5 self-end mx-auto text-white font-bold rounded-lg px-6 py-4 uppercase text-sm flex items-center space-x-2">
                <FaApple className="text-lg" /> {/* Apple Icon */}
                <span>Download on App Store</span>
            </button>

            {/* Google Play Button */}
            <button
                type="submit"
                className="inline-block mt-5 self-end mx-auto bg-teal-400 text-white font-bold rounded-lg px-6 py-4 uppercase text-sm flex items-center space-x-2"
            >
                <FaGooglePlay className="text-lg" /> {/* Google Play Icon */}
                <span>Download on Google Play</span>
            </button>
                </div>
            </div>
        </div>
    )
}

export default InApp