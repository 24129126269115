import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaLock, FaFileAlt, FaSignOutAlt, FaExchangeAlt, FaQuestionCircle } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
// import img from '../Assets/Man-1.jpg'
import DateTimeDisplay from "../Components/DateTimeDisplay";
import accountClass from "../Auth/account.class";

const TransferStatus = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [accountDetails,setAccountDetails]= useState()
    useEffect(()=>{
        const getAccountDetails = async()=>{
            try{
                const res = await accountClass.getAccount()
                console.log("res from bank",res?.data[0])
                setAccountDetails(res?.data[0])
            }catch(e){

            }
        }
        getAccountDetails();
    },[])
    return (
        <div className="min-h-screen flex">
            {/* Sidebar */}
            <div
                className={`w-64 bg-white shadow-lg p-4 fixed lg:static lg:h-full h-full lg:min-h-screen transition-transform duration-300 ease-in-out z-50 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } lg:translate-x-0`}
                style={{ height: "100vh" }}
            >
                <div className="flex justify-between items-center border-b pb-4">
                    <h1 className="text-lg font-bold text-blue-950 tracking-wide">Horizon View Bank</h1>
                    <button className="lg:hidden text-xl" onClick={() => setIsSidebarOpen(false)}>
                        <AiOutlineClose />
                    </button>
                </div>
                <p className="mt-2 text-gray-800 text-xl tracking-wide">{accountDetails?.accountName}</p>
                <p className="text-gray-800 text-xl tracking-wide mt-3">{accountDetails?.AccountNumber}</p>
                <div className="border-b mt-5"></div>
                <p className="text-green-600 mt-4 font-semibold text-xl">Status: Active</p>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Personal Menu</h3>
                    <ul className="mt-2">
                        <Link to='/bankprofile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaClipboardUser className="mr-2 text-blue-700" size={20} /> Dashboard
                        </li></Link>
                        <Link to='/profile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaUser className="mr-2 text-blue-700" size={20} /> My Profile
                        </li></Link>
                        <Link to='/changepassword'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaLock className="mr-2 text-blue-700" size={20} /> Change Password
                        </li></Link>
                        <Link to='/statement'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaFileAlt className="mr-2 text-blue-700" size={20} /> My Statement
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Transfers</h3>
                    <ul className="mt-2">
                        <Link> <li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaExchangeAlt className="mr-2 text-blue-700" size={20} /> Inter-Bank Transfer
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Settings</h3>
                    <ul className="mt-2">
                        <li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaQuestionCircle className="mr-2 text-blue-700" size={20} /> Get Support
                        </li>
                        <Link to='/login'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaSignOutAlt className="mr-2 text-blue-700" size={20} /> Logout
                        </li></Link>
                    </ul>
                </div>
            </div>

            {/* Main Content */}
            <div className="flex-1">
                {/* Navbar */}
                <nav className="bg-blue-600 text-white p-4 flex justify-between items-center">
                    <button className="lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                        ☰
                    </button>
                    <span className="font-bold hidden lg:block"> VExpress Platinum</span>
                    <div className="flex items-center space-x-4">
                        <input className="p-2 rounded bg-white text-black" type="text" placeholder="Search" />
                        <FaBell className="cursor-pointer" />
                        <FaCog className="cursor-pointer" />
                        <div className="relative group">
                            {/* <img
                                className="w-8 h-8 rounded-full cursor-pointer"
                                src={img}
                                alt="Profile"
                            /> */}
                            <div
                                className="absolute right-0 mt-2 bg-blue-600 text-white rounded shadow-lg p-2 w-48 z-50 hidden group-hover:block"
                            >
                                <p className="font-semibold text-center">{accountDetails?.accountName}</p>
                                <ul className="mt-2">
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaUser className="mr-2" /> Profile Details
                                    </li>
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaBell className="mr-2" /> Notifications
                                    </li>
                                    <Link to='/login'><li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaSignOutAlt className="mr-2" /> Logout
                                    </li></Link>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>

                {/* Profile Content */}
                <div className="p-6 bg-gray-100">

                    {/* Right Column */}
                    <div className="bg-white p-6 shadow rounded">
                        <h3 className="text-lg font-semibold">Horizon View Bank</h3>
                        <h1 className="text-gray-500 mt-10 right-0">
                        {accountDetails?.accountName}
                        </h1>

                        <h1 className="text-xl text-gray-700 font-medium">Transfer Status - <span className="font-bold text-gray-700">Pending...</span></h1>
                        <p className="font-medium text-gray-700"><DateTimeDisplay /></p>
                        <h3 className="text-gray-700 mt-8">Hello there!</h3>
                        <p className="text-xl text-gray-700 font-light mt-5">You are required to make a payment of 10% - of the total transaction to complete your transaction. Kindly contact customer care for more information.</p>

                        <div className="grid md:grid-cols-2 space-x-8 mt-10">
                            <div className="flex flex-col mx-auto md:mx-0">
                                <h2 className="text-gray-700 font-medium">Invoice</h2>
                                <p className="font-light text-gray-500">WGBS 3538</p>
                            </div>

                            <div className="flex flex-col mt-5 mx-auto">
                            <table className="w-full mx-auto mt-5 border-collapse border border-gray-300">
  <thead>
    <tr>
      <th className="text-gray-700 font-light text-sm text-center border border-gray-300">TRANSFER DESCRIPTION</th>
      <th className="text-gray-700 font-light text-sm text-center border border-gray-300">ACCOUNT NUMBER</th>
      <th className="text-gray-700 font-light text-sm text-center border border-gray-300">AVAILABLE BALANCE</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td className="text-gray-800 font-medium text-sm text-center border border-gray-300">Int-XXTransfer</td>
      <td className="text-gray-800 font-medium text-sm text-center border border-gray-300">{accountDetails?.AccountNumber}</td>
      <td className="text-gray-800 font-medium text-sm text-center border border-gray-300">${accountDetails?.available_amount}</td>
    </tr>
  </tbody>
</table>


                                <div className="border-b mt-5"></div>

                                <h2 className="text-gray-700 font-medium mt-10">Terms and Conditions</h2>
                                <p className="text-gray-700 font-light mt-5">Cost of Transfer Code (cot) is essential for international transfers as stated in FRS *NC US.2024.</p> 
                            </div>

                        </div>
                            <div className="border-b mt-5"></div>
                            <div className="grid grid-cols-2 mt-5">
                                <div>
                                    <h1>Horizon View Bank</h1>
                                </div>
                                <div>
                                    <h1>support@horizonviewbk.org</h1>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TransferStatus;
