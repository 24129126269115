import React from 'react'
import { FaFacebook, FaLinkedin, FaPinterest } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'

function Footer() {
  return (
    <div className='w-full px-4 bg-blue-950 '>

      <div className='max-w-[1240px] mx-auto grid md:grid-cols-4 pb-10 md:pb-20 space-x-5 md:space-x-12'>

        <div className='flex flex-col mt-10 md:mt-20'>
          <h1 className=" text-3xl md:text-4xl font-medium text-white">
            Horizon View Bank
          </h1>
          <p className="mt-5 text-gray-400 tracking-wide">
            Horizon View Bank is Authorized by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority (Financial Services Register number:122702)
          </p>

          <div className='flex flex-row '>
            <ul className='flex flex-row space-x-4 mt-5 '>

              <li className='text-teal-400 bg-blue-700 p-3 rounded-md'><FaFacebook size={20} /></li>
              <li className='text-teal-400 bg-blue-700 p-3 rounded-md'><FaXTwitter size={20} /></li>
              <li className='text-teal-400 bg-blue-700 p-3 rounded-md'><FaLinkedin size={20} /></li>
              <li className='text-teal-400 bg-blue-700 p-3 rounded-md'><FaPinterest size={20} /></li>
            </ul>
          </div>
        </div>

        <div className='flex flex-col mt-10 md:mt-20'>
  <h1 className="text-3xl md:text-4xl font-medium text-white">Our Company</h1>

  {[
    "Company & Team",
    "Our Services",
    "News & Blog",
    "Pricing Plan",
    "Contact Us",
    "Privacy Policy",
  ].map((text, index) => (
    <div key={index} className="flex items-center mt-3">
      <span className="text-teal-400 mr-2">&gt;</span>
      <p className="text-gray-400 tracking-wide">{text}</p>
    </div>
  ))}
</div>

<div className='flex flex-col mt-10 md:mt-20'>
  <h1 className="text-3xl md:text-4xl font-medium text-white">Products</h1>

  {[
    "Online Payment",
    "Deposit Skim",
    "Online Shopping",
    "Master Card",
    "Receive Money",
    "Affiliate Program",
  ].map((text, index) => (
    <div key={index} className="flex items-center mt-3">
      <span className="text-teal-400 mr-2">&gt;</span>
      <p className="text-gray-400 tracking-wide">{text}</p>
    </div>
  ))}
</div>


        <div className='flex flex-col mt-10 md:mt-20'>
          <h1 className=" text-3xl md:text-4xl font-medium text-white">
            Suscribe
          </h1>

          <p className='text-gray-400 tracking-wide mt-5'>To get exclusive updates and benefits.</p>


          <div>
            <input type='email' placeholder='Your Email Address' name='user_email' required className='ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-teal-300 '
            />
          </div>

          <button type='submit' className='inline-block mt-5 self-end mx-auto bg-teal-400 text-white font-bold rounded-lg px-6 py-2 uppercase text-sm'>Suscribe</button>

        </div>
      </div>

      <div>
                <hr className='w-full border-gray-300'/>
                <h1 className='text-gray-200 text-center mt-3 pb-5'>©Copyright 2024 Horizon View Bank All Rights Reserved</h1>
            </div>
    </div>
  )
}

export default Footer