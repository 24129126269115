import React from 'react'
import img1 from '../Assets/MoApp.png'
import { FaApple, FaGooglePlay } from 'react-icons/fa'

function MobileApp() {
    return (
        <div className='w-full px-4 mt-10'>
            <div className='max-w-[1240px] gap-12 grid md:grid-cols-2 mx-auto'>
                <div className="flex justify-center items-center">
                    <img src={img1} alt="/img" className=" mx-auto" />
                </div>

            <div className="flex flex-col justify-center">
                <p className="text-teal-400 text-xl ">
                Download Our Mobile App
                </p>
                <h1 className="mt-5 text-3xl md:text-5xl font-medium">
                You Can Find All Things You Need In Our App
                </h1>
                <p className="mt-5 text-gray-500 tracking-wide">
                Interested in opening an account with Horizon view Bank. Whether you're starting to save or planning for the long haul, our competitive rates can help make those decisions easier.
                </p>

                <div className='flex flex-row space-x-4 mt-5'>
                <button className="bg-blue-900 inline-block mt-5 self-end mx-auto text-white font-bold rounded-lg px-6 py-4 uppercase text-sm flex items-center space-x-2">
                <FaApple className="text-lg" /> {/* Apple Icon */}
                <span>Download on App Store</span>
            </button>

            {/* Google Play Button */}
            <button
                type="submit"
                className="inline-block mt-5 self-end mx-auto bg-teal-400 text-white font-bold rounded-lg px-6 py-4 uppercase text-sm flex items-center space-x-2"
            >
                <FaGooglePlay className="text-lg" /> {/* Google Play Icon */}
                <span>Download on Google Play</span>
            </button>
                </div>
            </div>
            </div>

        </div>
    )
}

export default MobileApp