import React from 'react'

function ContactUs() {
    return (
        <div className='w-full bg-teal-50 px-4 pb-10 md:pb-20 mt-10'>
            <div className='flex flex-col mx-auto text-center'>
                <p className="text-teal-400 text-xl mt-10">
                    Contact Us
                </p>
                <h1 className="mt-5 text-3xl md:text-4xl font-medium">
                    Horizon View Bank Is A Quick Solution For Business Payments
                </h1>

                <div className='flex flex-row justify-center space-x-4 mt-10'>
                    <button className='bg-teal-400 text-white text-center p-4 rounded-md'>Personal Account</button>

                    <button className='bg-blue-900 text-white text-center p-4 rounded-md'>Business Account</button>
                </div>
            </div>
        </div>
    )
}

export default ContactUs