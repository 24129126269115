import React from 'react'
import img1 from '../Assets/securities.png'
import img2 from '../Assets/encrypted.png'
import img3 from '../Assets/BaSecure.png'

function BankingSecurity() {
  return (
    <div className='w-full px-4 mt-10 md:mt-20'>
      <div className='max-w-[1240px] gap-12 grid md:grid-cols-2 mx-auto'>

        <div className="flex flex-col justify-center">
          <p className="text-teal-400 text-xl ">
            Banking Security
          </p>
          <h1 className="mt-5 text-3xl md:text-5xl font-medium">
            The Safest Way To Transact Your Money Fast
          </h1>
          <p className="mt-5 text-gray-500 tracking-wide">
            Our aim is to reduce our negative social, economic and environmental footprints while enhancing the overall value we create as a leading brand.
          </p>

          <div className='flex py-2 mt-5'>
            <div className='flex flex-row gap-5'>
              <img src={img1} className='bg-teal-400 w-[60px] h-[60px] p-2' />
              <div className='flex flex-col'>
                <h1 className='text-2xl font-medium'>Pay Online Securely</h1>
                <p className='text-gray-500 mt-4 tracking-wide'>Tell us a little bit about yourself and we’ll be in touch with more information. Or click Open an Account to access our quick and easy account opening tool.</p>
              </div>
            </div>
          </div>


          <div className='flex py-2 mt-5'>
            <div className='flex flex-row gap-5'>
              <img src={img2} className='bg-teal-400 w-[60px] h-[60px] p-2' />
              <div className='flex flex-col'>
                <h1 className='text-2xl font-medium'>Horizon View Bank Means More</h1>
                <p className='text-gray-500 mt-4 tracking-wide'>In business, an unseen challenge is always ahead. It shouldn't be your bank.</p>
              </div>
            </div>
          </div>

        </div>

      <div className="flex justify-center items-center">
          <img src={img3} alt="/img" className=" mx-auto" />
        </div>

      </div>

    </div>
  )
}

export default BankingSecurity