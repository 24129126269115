// import React, { useState } from "react";
// import { FaSearch, FaBars } from "react-icons/fa";

// function Navbar() {
//   const [firstNavOpen, setFirstNavOpen] = useState(false);
//   const [secondNavOpen, setSecondNavOpen] = useState(false);

//   return (
//     <div>
//       {/* First Nav */}
//       <nav className="bg-blue-950 text-white p-4">
//         <div className="flex items-center justify-between">
//           <button
//             onClick={() => setFirstNavOpen(!firstNavOpen)}
//             className="lg:hidden"
//           >
//             <FaBars size={24} />
//           </button>
//           <div className="lg:hidden">
//             <FaSearch size={24} />
//           </div>
//           <button
//             onClick={() => setSecondNavOpen(!secondNavOpen)}
//             className="lg:hidden"
//           >
//             <FaBars size={24} />
//           </button>
//         </div>
//         <div
//   className={`mx-7 grid grid-cols-2 lg:flex lg:items-center lg:justify-between gap-4 ${
//     firstNavOpen ? "block" : "hidden lg:grid"
//   }`}
// >
//   {/* Left Side */}
//   <div className="flex flex-col md:flex-row gap-5">
//     <span>support@westglobebk.org</span>
//     <span>helpdesk@westglobebk.org</span>
//     <span>St. Here Mandalay, New York, U.S.A</span>
//   </div>
  
//   {/* Right Side */}
//   <div className="flex md:flex-row gap-5 flex-col lg:ml-auto">
//     <span>Support</span>
//     <span>Help</span>
//   </div>
// </div>

//       </nav>

//       {/* Second Nav */}
//       <nav className="bg-white text-black p-4 shadow">
//         <div className="flex items-center justify-between">
//           <button
//             onClick={() => setSecondNavOpen(!secondNavOpen)}
//             className="lg:hidden"
//           >
//             <FaBars size={24} />
//           </button>
//           <div className="lg:hidden">
//             <FaSearch size={24} />
//           </div>
//         </div>
//         <div
//           className={`grid grid-cols-3 lg:flex lg:flex-col ${
//             secondNavOpen ? "block" : "hidden lg:grid"
//           }`}
//         >
//           {/* Left Side */}
//           <div>
//             <span>West Bank</span>
//           </div>
//           {/* Middle */}
//           <div className="flex justify-center space-x-4">
//             <a href="/">Home</a>
//             <a href="/">About Us</a>
//             <a href="/">Contact Us</a>
//             <a href="/">Privacy Policy</a>
//           </div>
//           {/* Right Side */}
//           <div className="flex justify-end space-x-4">
//             <div className="relative">
//               {/* <FaSearch
//                 size={20}
//                 className="absolute top-1/2 left-2 transform -translate-y-1/2"
//               /> */}
//               <input
//                 type="text"
//                 placeholder="Search"
//                 className="border-b focus:outline-none pl-8"
//               />
//             </div>
//             <button className="bg-blue-950 text-white px-4 py-2 rounded">
//               Register Now
//             </button>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// };

// export default Navbar;

















import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";

function Navbar() {
  const [firstNavOpen, setFirstNavOpen] = useState(false);
  const [secondNavOpen, setSecondNavOpen] = useState(false);

  return (
    <div>
      {/* Unified Navbar for Small Screens */}
      <nav className="bg-white text-black p-4 shadow lg:hidden">
        <div className="flex items-center justify-between">
          {/* First Nav Hamburger */}
          <button
            onClick={() => setFirstNavOpen(!firstNavOpen)}
            className="text-black"
          >
            <FaBars size={24} />
          </button>

          {/* Second Nav Hamburger */}
          <button
            onClick={() => setSecondNavOpen(!secondNavOpen)}
            className="bg-teal-500 text-white rounded p-1"
          >
            <FaBars size={24} />
          </button>
        </div>

        {/* Dropdown for First Nav */}
        {firstNavOpen && (
          <div className="mt-4 space-y-2">
            <div className="flex flex-col space-y-1">
              <span>support@horizonviewbk.org</span>
              <span>helpdesk@horizonviewbk.org</span>
              <span>St. Here Mandalay, New York, U.S.A</span>
            </div>
            <div className="border-t pt-2">
              <span className="block">Support</span>
              <span className="block">Help</span>
            </div>
          </div>
        )}

        {/* Dropdown for Second Nav */}
        {secondNavOpen && (
          <div className="mt-4 space-y-2">
            <div>
              <span>Horizon View Bank</span>
            </div>
            <div className="flex flex-col cursor-pointer space-y-1">
             <Link to='/'><p classname='hover:text-teal-400'>Home</p></Link>
              <Link to='/about'><p classname='hover:text-teal-400'>About Us</p></Link>
              <Link to='/contact'><p classname='hover:text-teal-400'>Contact Us</p></Link>
              <Link to='/privacy'><p classname='hover:text-teal-400'>Privacy Policy</p></Link>
            </div>
            <div className="flex flex-col space-y-2 pt-2 border-t">
              <input
                type="text"
                placeholder="Search"
                className="border-b focus:outline-none"
              />
              <Link to='/register'><button className="bg-blue-950 text-white px-4 py-2 rounded">
                Register Now
              </button></Link>
            </div>
          </div>
        )}
      </nav>

      {/* Desktop Layout */}
      <div className="hidden lg:block">
        {/* First Nav */}
        <nav className="bg-blue-950 text-white p-4">
          <div className="flex justify-between">
            <div className="flex flex-col md:flex-row gap-5 mx-7">
              <span>support@horizonviewbk.org</span>
              <span>helpdesk@horizonviewbk.org</span>
              <span>St. Here Mandalay, New York, U.S.A</span>
            </div>
            <div className="border-l pl-4 flex flex-col md:flex-row gap-5 mx-7">
              <Link to='/contact'><span>Support</span></Link>
              <Link to='/contact'><span>Help</span></Link>
            </div>
          </div>
        </nav>

        {/* Second Nav */}
        <nav className="bg-white text-black p-4 shadow">
          <div className="flex justify-between items-center">
            <div>
              <span className="text-gray-950 font-medium text-2xl tracking-wider"> Horizon View Bank</span>
            </div>
            <div className="flex space-x-4 cursor-pointer">
  <Link to='/'><p className="hover:text-teal-400">Home</p></Link>
  <Link to='/about'><p className="hover:text-teal-400">About Us</p></Link>
  <Link to='/contact'><p className="hover:text-teal-400">Contact Us</p></Link>
  <Link to='/privacy'><p className="hover:text-teal-400">Privacy Policy</p></Link>
</div>
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Search"
                className="border-b focus:outline-none"
              />
              <Link to='register'><button className="bg-blue-950 text-white px-4 py-2 rounded">
                Register Now
              </button></Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
