import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaLock, FaFileAlt, FaSignOutAlt, FaExchangeAlt, FaQuestionCircle } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import accountClass from "../Auth/account.class";

const Transfer = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState();

  useEffect(() => {
    const getAccountDetails = async () => {
      try {
        const res = await accountClass.getAccount();
        setAccountDetails(res?.data[0]);
      } catch (e) {
        console.error("Error fetching account details", e);
      }
    };
    getAccountDetails();
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    accountNumber: Yup.string().required("Required"),
    accountName: Yup.string().required("Required"),
    bankName: Yup.string().required("Required"),
    amount: Yup.number().min(1, "Amount must be greater than 0").required("Required"),
    accountType: Yup.string().required("Required"),
    swiftCode: Yup.string().required("Required"),
    routingNumber: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    terms: Yup.bool().oneOf([true], "You must accept the terms and conditions"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form data", values);
    setSubmitting(false);
    window.location.href = "/transferstatus"
  };

  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div
        className={`w-64 bg-white shadow-lg p-4 fixed lg:static lg:h-full h-full lg:min-h-screen transition-transform duration-300 ease-in-out z-50 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
        style={{ height: "100vh" }}
      >
        {/* Sidebar content */}
      </div>

      {/* Main Content */}
      <div className="flex-1">
        {/* Navbar */}
        <nav className="bg-blue-600 text-white p-4 flex justify-between items-center">
          <button className="lg:hidden" onClick={() => setIsSidebarOpen(true)}>
            ☰
          </button>
          <span className="font-bold hidden lg:block"> VExpress Platinum</span>
          <div className="flex items-center space-x-4">
            <input className="p-2 rounded bg-white text-black" type="text" placeholder="Search" />
            <FaBell className="cursor-pointer" />
            <FaCog className="cursor-pointer" />
          </div>
        </nav>

        {/* Profile Content */}
        <div className="p-6 bg-gray-100">
          <div className="bg-white p-6 shadow rounded">
            <h3 className="text-lg font-semibold">Transfer Funds</h3>
            <p className="text-gray-500 mt-2">
              Transfer internationally to over 54 countries with west globe pay attitude.
            </p>

            <Formik
              initialValues={{
                email: "",
                accountNumber: "",
                accountName: "",
                bankName: "",
                amount: "",
                accountType: "",
                swiftCode: "",
                routingNumber: "",
                country: "",
                terms: false,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="mt-8 space-y-4">
                  <div>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Email Address"
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-600 text-sm" />
                  </div>
                  <div className="flex space-x-4">
                    <div>
                      <Field
                        name="accountNumber"
                        type="text"
                        placeholder="Account Number"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="accountNumber" component="div" className="text-red-600 text-sm" />
                    </div>
                    <div>
                      <Field
                        name="accountName"
                        type="text"
                        placeholder="Account Name"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="accountName" component="div" className="text-red-600 text-sm" />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div>
                      <Field
                        name="bankName"
                        type="text"
                        placeholder="Bank Name"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="bankName" component="div" className="text-red-600 text-sm" />
                    </div>
                    <div>
                      <Field
                        name="amount"
                        type="number"
                        placeholder="Amount"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="amount" component="div" className="text-red-600 text-sm" />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div>
                      <Field as="select" name="accountType" className="w-full p-2 border border-gray-300 rounded">
                        <option value="" disabled>
                          Account Type
                        </option>
                        <option value="savings">Savings</option>
                        <option value="current">Current</option>
                        <option value="checkings">Checkings</option>
                      </Field>
                      <ErrorMessage name="accountType" component="div" className="text-red-600 text-sm" />
                    </div>
                    <div>
                      <Field
                        name="swiftCode"
                        type="text"
                        placeholder="IFSC/Swift Code"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="swiftCode" component="div" className="text-red-600 text-sm" />
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div>
                      <Field
                        name="routingNumber"
                        type="text"
                        placeholder="Routing Transit (RTN) / IBAN"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="routingNumber" component="div" className="text-red-600 text-sm" />
                    </div>
                    <div>
                      <Field
                        name="country"
                        type="text"
                        placeholder="Country"
                        className="w-full p-2 border border-gray-300 rounded"
                      />
                      <ErrorMessage name="country" component="div" className="text-red-600 text-sm" />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Field type="checkbox" name="terms" id="terms" className="mr-2" />
                    <label htmlFor="terms" className="text-gray-600">
                      I agree to terms and conditions
                    </label>
                    <ErrorMessage name="terms" component="div" className="text-red-600 text-sm ml-2" />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
