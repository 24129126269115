import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./Components/Home";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ScrollToTop from "./Components/ScrollToTop";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import BankingProfile from "./Admin/BankingProfile";
import ProfilePage from "./Pages/Profile";
import ChangePassword from "./Pages/ChangePassword";
import Statement from "./Pages/Statement";
import Transfer from "./Pages/Transfer";
import TransferStatus from "./Pages/TransferStatus";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAuthUser } from "./Auth/auth";

// Protected Route HOC
const ProtectedRoute = ({ element, ...rest }) => {
 
  const authUser = getAuthUser() &&  getAuthUser()['token'];
const isAuthenticated = authUser ? true : false;

  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop>
          <ToastContainer />
          <Routes>
            <Route path="/" index element={<Home />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />

            {/* Protected Routes */}
            <Route
              path="bankprofile"
              element={<ProtectedRoute element={<BankingProfile />} />}
            />
            <Route
              path="profile"
              element={<ProtectedRoute element={<ProfilePage />} />}
            />
            <Route
              path="changepassword"
              element={<ProtectedRoute element={<ChangePassword />} />}
            />
            <Route
              path="statement"
              element={<ProtectedRoute element={<Statement />} />}
            />
            <Route
              path="transfer"
              element={<ProtectedRoute element={<Transfer />} />}
            />
            <Route
              path="transferstatus"
              element={<ProtectedRoute element={<TransferStatus />} />}
            />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
