import React from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import smart from '../Assets/smartBA.png'

function SmartBanking() {
  return (
    <div className='w-full px-4 py-6 mt-10'>
        <div className='max-w-[1240px] gap-10 md:gap-24 mx-auto grid md:grid-cols-2'>

        <div className="flex justify-center items-center">
          <img src={smart} alt="/img" className=" mx-auto" />
        </div>


        <div className="flex flex-col justify-center">
          <p className="text-teal-400 text-xl ">
            Smart Banking
          </p>
          <h1 className="mt-5 text-3xl md:text-5xl font-medium">
          Transfer & Deposit Money Anytime, Anywhere In The World
          </h1>
          <p className="mt-10 text-gray-500 tracking-wide">
          At Horizon View Bank, we believe that the beauty of life lies in the little things that give us a better expression of ourselves , make us more social and connect emotionally.
          </p>
          
          <div className='flex py-2 mt-5'>
            <div className='flex flex-row gap-3'>
          <FaCircleCheck className='text-teal-400' size={25}/>
          <p className='font-medium'>Cards that work all across the world.</p>
          </div>
          </div>

          <div className='flex py-2'>
            <div className='flex flex-row gap-3'>
          <FaCircleCheck className='text-teal-400' size={25}/>
          <p className='font-medium'>Highest Returns on your investments.</p>
          </div>
          </div>

          <div className='flex py-2'>
            <div className='flex flex-row gap-3'>
          <FaCircleCheck className='text-teal-400' size={25}/>
          <p className='font-medium'>No ATM fees. No minimum balance. No overdrafts.</p>
          </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default SmartBanking