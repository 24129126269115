import React, { useEffect, useState } from "react";
import { FaBell, FaCog, FaUser, FaLock, FaFileAlt, FaSignOutAlt, FaExchangeAlt, FaQuestionCircle } from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
// import { FaClipboardList } from "react-icons/fa6";
import pro from '../Assets/ProfileArt.jpg'
import { Link } from "react-router-dom";
import accountClass from "../Auth/account.class";
// import img from '../Assets/Man-1.jpg'

const ProfilePage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [accountDetails,setAccountDetails]= useState()
  useEffect(()=>{
      const getAccountDetails = async()=>{
          try{
              const res = await accountClass.getAccount()
              console.log("res from bank",res?.data[0])
              setAccountDetails(res?.data[0])
          }catch(e){

          }
      }
      getAccountDetails();
  },[])
  return (
    <div className="min-h-screen flex">
      {/* Sidebar */}
      <div
                className={`w-64 bg-white shadow-lg p-4 fixed lg:static lg:h-full h-full lg:min-h-screen transition-transform duration-300 ease-in-out z-50 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } lg:translate-x-0`}
                style={{ height: "100vh" }}
            >
                <div className="flex justify-between items-center border-b pb-4">
                    <h1 className="text-lg font-bold text-blue-950 tracking-wide">Horizon View Bank</h1>
                    <button className="lg:hidden text-xl" onClick={() => setIsSidebarOpen(false)}>
                        <AiOutlineClose />
                    </button>
                </div>
                <p className="mt-2 text-gray-800 text-xl tracking-wide">{accountDetails?.accountName}</p>
                <p className="text-gray-800 text-xl tracking-wide mt-3">{accountDetails?.AccountNumber}</p>
                <div className="border-b mt-5"></div>
                <p className="text-green-600 mt-4 font-semibold text-xl">Status: Active</p>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Personal Menu</h3>
                    <ul className="mt-2">
                        <Link to='/bankprofile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaClipboardUser className="mr-2 text-blue-700" size={20} /> Dashboard
                        </li></Link>
                        <Link to='/profile'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaUser className="mr-2 text-blue-700" size={20} /> My Profile
                        </li></Link>
                        <Link to='/changepassword'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaLock className="mr-2 text-blue-700" size={20} /> Change Password
                        </li></Link>
                        <Link to='/statement'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaFileAlt className="mr-2 text-blue-700" size={20} /> My Statement
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Transfers</h3>
                    <ul className="mt-2">
                    <Link to='/transfer'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaExchangeAlt className="mr-2 text-blue-700" size={20} /> Inter-Bank Transfer
                        </li></Link>
                    </ul>
                </div>
                <div className="mt-6">
                    <h3 className="text-blue-700 font-semibold text-xl tracking-wide">Settings</h3>
                    <ul className="mt-2">
                        <li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaQuestionCircle className="mr-2 text-blue-700" size={20} /> Get Support
                        </li>
                       <Link to='/login'><li className="flex items-center mt-5 md:mt-7 text-sm font-medium text-gray-800">
                            <FaSignOutAlt className="mr-2 text-blue-700" size={20} /> Logout
                        </li></Link>
                    </ul>
                </div>
            </div>

      {/* Main Content */}
      <div className="flex-1">
        {/* Navbar */}
        <nav className="bg-blue-600 text-white p-4 flex justify-between items-center">
                    <button className="lg:hidden" onClick={() => setIsSidebarOpen(true)}>
                        ☰
                    </button>
                    <span className="font-bold hidden lg:block"> VExpress Platinum</span>
                    <div className="flex items-center space-x-4">
                        <input className="p-2 rounded bg-white text-black" type="text" placeholder="Search" />
                        <FaBell className="cursor-pointer" />
                        <FaCog className="cursor-pointer" />
                        <div className="relative group">
                            {/* <img
                                className="w-8 h-8 rounded-full cursor-pointer"
                                src={img}
                                alt="Profile"
                            /> */}
                            <div
                                className="absolute right-0 mt-2 bg-blue-600 text-white rounded shadow-lg p-2 w-48 z-50 hidden group-hover:block"
                            >
                                <p className="font-semibold text-center">{accountDetails?.accountName}</p>
                                <ul className="mt-2">
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaUser className="mr-2" /> Profile Details
                                    </li>
                                    <li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaBell className="mr-2" /> Notifications
                                    </li>
                                    <Link to='/login'><li className="flex items-center mt-2 text-sm font-medium text-gray-100">
                                        <FaSignOutAlt className="mr-2" /> Logout
                                    </li></Link>
                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>

        {/* Profile Content */}
        <div className="p-6 bg-gray-100">
          <div className="grid lg:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="bg-white p-6 shadow rounded">
              <img
                className="w-[250px] rounded-full mx-auto"
                src={pro}
                alt="Profile"
              />
              <div className="flex justify-between items-center mt-4">
                <p className="text-green-600 font-semibold">Status: Active</p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded">Send Referral</button>
              </div>
              <hr className="my-4" />
              <div className="grid grid-cols-2 items-center text-center">
                <div>
                  <p className="text-3xl font-bold">0</p>
                  <p className="text-gray-500">Bonuses</p>
                </div>
                <div>
                  <p className="text-3xl font-bold">0</p>
                  <p className="text-gray-500">Referrals</p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="space-y-4">
                <div>
                  <p className="text-gray-600">Profile Completion</p>
                  <div className="w-full bg-gray-200 h-4 rounded">
                    <div className="bg-blue-700 h-4 rounded" style={{ width: "72%" }}></div>
                  </div>
                </div>
                <div>
                  <p className="text-gray-600">Status Unlocked</p>
                  <div className="w-full bg-gray-200 h-4 rounded">
                    <div className="bg-blue-700 h-4 rounded" style={{ width: "45%" }}></div>
                  </div>
                </div>
                <div>
                  <p className="text-gray-600">Bonuses</p>
                  <div className="w-full bg-gray-200 h-4 rounded">
                    <div className="bg-blue-700 h-4 rounded" style={{ width: "74%" }}></div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="bg-white p-6 shadow rounded">
              <h3 className="text-lg font-semibold">Profile Settings</h3>
              <p className="text-gray-500 mt-2">
                Validation of the form is made possible using a powerful validator plugin for West Globe Bank.
              </p>
              <form className="mt-8 space-y-4">
                <input
                  type="email"
                  placeholder="Email Address"
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div className="flex space-x-4">
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                </div>
                <div className="flex space-x-4">
                  <input
                    type="text"
                    placeholder="Residential Address"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                </div>
                <div className="flex space-x-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="w-1/2 p-2 border border-gray-300 rounded mt-5"
                  />
                </div>
                <div className="flex items-center">
                  <input type="checkbox" id="terms" className="mr-2 mt-5" />
                  <label htmlFor="terms" className="text-gray-600 mt-5">
                    I agree to terms and conditions
                  </label>
                </div>
                <hr className="my-4 pb-4" />
                <button className="bg-blue-600 text-white px-4 py-2 rounded w-full">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
