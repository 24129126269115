import React from 'react'
import img1 from '../Assets/client-1.jpg'
import img2 from '../Assets/client-2.jpg'
import img3 from '../Assets/client-3.jpg'
import img4 from '../Assets/client-4.jpg'
import img5 from '../Assets/client-5.jpg'

function Testimonials() {


    return (
        <div className='w-full px-4 mt-10 md:mt-20 bg-gray-100 pb-10 md:pb-20'>
                <div className='mx-auto text-center flex flex-col '>
                    <h1 className='text-center mx-auto text-teal-400 text-xl mt-10 font-medium'>
                    Our Testimonials
                    </h1>
                    <h1 className='text-3xl md:text-5xl mt-5'>What Customers Say</h1>
                </div>

                 <div className='max-w-[1240px] gap-10 mx-auto grid grid-cols-2 md:grid-cols-3 '>


                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img1} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            I use Horizon View Bank Often. Thanks Horizon View Bank.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4 '>Mark Owen</h1>
                        <p className='mt-2 text-gray-500'>Enterpreneur</p>
                    </div>

                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img2} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            I love your system. This is is simple unbelievable! It's really wonderful. Horizon View Bank was the best investment i ever made.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4 '>Jim Morrison</h1>
                        <p className='mt-2 text-gray-500'>Customerr</p>
                    </div>

                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img3} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            You won't regret it. After using Metro Savings Bank, my business skyrocketed! It's exactly what i've been looking for.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4 mt-4'>Alex Cruis</h1>
                        <p className='mt-2 text-gray-500'>Customer</p>
                    </div>

                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img4} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            Sibce i invested in Horizon View Bank, i made over $100,000 in profit. It's incredible. We have no regrets! Horizon View Bank is both attractive and highly adaptable.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4'>Tom Harris</h1>
                        <p className='mt-2 text-gray-500'>Customer</p>
                    </div>

                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img5} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            Not able to tell you how happy i am with Horizon View Bank. We're loving it.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4'>Harry Jackson</h1>
                        <p className='mt-2 text-gray-500'>Enterpreneur</p>
                    </div>

                    <div className='shadow-xl bg-white flex flex-col p-2 my-4 text-center justify-center transform transition-transform duration-700 hover:scale-90 mt-10'>
                        <img src={img5} alt='/img' className='text-center mx-auto w-20 rounded-full' />

                        <p className="p-4 font-medium text-gray-800">
                            Keep up the excellent work. Really good.
                        </p>

                        <h1 className='font-medium text-xl p-3 mx-4'>Chris Knyt</h1>
                        <p className='mt-2 text-gray-500'>Customer</p>
                    </div>
                    
                </div>



            </div>
    )
}

export default Testimonials